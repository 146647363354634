import customerLogo from './assets/aws-logo-on-dark-bg.png';

const config = {
  HEADER_TITLE: "",
  HEADER_LOGO: customerLogo,
  // MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    API_NAME: "PrototypeQualityAPI",
    REGION: "us-east-1",
    API_URL: "https://demofactory.quality.prototyping.aws.dev/df"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_3NCosWTZK",
    APP_CLIENT_ID: "1r6acirjtva73aknrufhagvtru",
    IDENTITY_POOL_ID: "us-east-1:78529410-565a-463d-ad4a-325edf42da4d",
    OAUTH: {
      APP_CLIENT_DOMAIN: "df-qap-domain.auth.us-east-1.amazoncognito.com",
      SCOPE: ['email', 'openid'],
      REDIRECT_SIGN_IN: "https://demofactory.quality.prototyping.aws.dev/",
      REDIRECT_SIGN_OUT: "https://demofactory.quality.prototyping.aws.dev/",
      RESPONSE_TYPE: 'code'
    }
  },
  environment: {
    profileName: "df",
    webComponentsPath: "demofactory/",
  },
  feedback: {
    ASANA_FORM_URL: 'https://form.asana.com/?k=iTcQ3yA1PkuX6qL3jA3O4g&d=8442528107068'
  }
};

export default config;