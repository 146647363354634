// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from "react";
import { Container, Grid, Button, Header, Divider, Segment, List, Image, Embed } from 'semantic-ui-react';
import QualityHeader from "../../assets/quality-header.png";
import Announcement from "../../assets/announcement.png";
import GenAITeaserVideo from '../../assets/qap-v2-genai-teaser.mp4';
import GenAITeaserThumbnail from '../../assets/qap-v2-genai-teaser.png';

export default class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.content = {
            subtitle: "The Quality Assurance Portal is a web tool that performs a series of automated quality checks against prototype repositories and report found issues to developers for further analysis and correction. The main goal of the tool is to raise the quality bar for the prototypes we build for our beloved customers.",
            action: {
                header: "Get Started",
                button: {
                    dev: "Create Repository",
                    management: "View Dashboard"
                },
                text: {
                    dev: "Onboard your prototype code.",
                    management: "Observe prototype quality metrics."
                },
                state: "Developer",
            },
            links: [
                {
                    header: "How to use QAP",
                    links: [
                        {
                            text: "QAP Wiki",
                            href: "https://w.amazon.com/bin/view/BDSI_Solutions_Prototyping/QualityAssurancePortal/",
                        },
                        {
                            text: "Hosting Options",
                            href: "https://w.amazon.com/bin/view/BDSI_Solutions_Prototyping/QualityAssurancePortal/#HCodeRepositoryHostingOptionsDuringPrototypeDevelopment"
                        }
                    ]
                },
                {
                    header: "Additional Resources",
                    links: [
                        {
                            text: "Release Details",
                            href: "https://w.amazon.com/bin/view/BDSI_Solutions_Prototyping/QualityAssurancePortal/Releases",
                        },
                        {
                            text: "QAP Interest Email List",
                            href: "https://email-list.corp.amazon.com/email-list/expand-list/aws-quality-assurance-portal-interest",
                        },
                        {
                            text: "How can I propose a new quality check?",
                            href: "https://form.asana.com/?k=UMo-olEoKWZ9iA-5KXUmWA&d=8442528107068&rr=563284",
                        },
                        {
                            text: "Provide Feedback",
                            href: "https://form.asana.com/?k=iTcQ3yA1PkuX6qL3jA3O4g&d=8442528107068",
                        },
                        {
                            text: "Email Us",
                            href: "mailto:aws-quality-assurance-portal@amazon.com"
                        }
                    ]
                }
            ],
            directions: {
                header: "How it Works",
                text: [
                    "Create a code repository",
                    "Push changes to the repository",
                    "Receive automated code analysis results in your email",
                    "Use embedded Generative AI support to fix your code and continue from step 2",
                    "Alternatively, disagree with a finding by providing feedback to experts (e.g., suggest its suppression)",
                    "Review quality statistics and common findings across multiple repositories",
                    "Provide feedback and suggestions to improve the tool",
                    "Be Awesome! Create your own quality check and win a phone tool icon!"
                ],
            },
            benefits_features: {
                header: "Benefits & Features",
                text: [
                    "Repository Creation: create a code repository for your project following the expected naming convention and with automated code analysis enabled",
                    "Code Analysis: receive automated code analysis results in your email or through a rich web dashboard",
                    "Generative AI: receive support from GenAI to fix code analysis findings",
                    "Disagree with an analysis finding and have QAP enforce its suppression or change in severity level (e.g., from high to low)",
                    "Quality Statistics: check out statistics about the various code analysis categories across individual or multiple repositories",
                    "Common Findings: find out what the most common issues are across all repositories analyzed",
                    "Security Reviews: use the full quality report to run security reviews with ProtoSec champs and have your code ready for customers"
                ],
            }
        }
        this.handleActionChange = this.handleActionChange.bind(this);
    }

    renderDashboardContainer(content) {
        return (
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        {content}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    renderContentHeader(header) {
        return <Header as='h3' attached='top'>{header}</Header>;
    }

    renderListContent(content) {
        const { header, text } = content;

        return (
            <Grid.Column>
                {this.renderContentHeader(header)}
                <Segment attached>
                    <List ordered>
                        {text.map((value, index) => {
                            if (value.includes(":")) {
                                const splitValue = value.split(":");
                                return (
                                    <List.Item key={index}>
                                        <b>{splitValue[0]}</b>: {splitValue[1]}
                                    </List.Item>
                                );
                            } else {
                                return <List.Item key={index}>{value}</List.Item>;
                            }

                        })}
                    </List>
                </Segment>
            </Grid.Column>
        )
    }

    handleActionChange(e, { name }) {
        this.setState({
            action: {
                ...this.content.action,
                state: name,
            }
        })
    }

    handleClick = async (e, { name, link }) => {
        if (name) {
            let link = '/'
            link = link + name;
            this.props.history.push(link);
        }
        if (link) window.open(link);
    }

    renderActionContent(content) {
        const { header, button, text } = content;
        const developerState = this.content.action.state === "Developer";
        return (
            <Grid.Column>
                {this.renderContentHeader(header)}
                <Segment attached>
                    <Button.Group>
                        <Button positive={developerState} name="Developer" onClick={this.handleActionChange}>
                            Developer
                        </Button>
                        <Button.Or />
                        <Button positive={!developerState} name="Management" onClick={this.handleActionChange}>
                            Management
                        </Button>
                    </Button.Group>
                    <p />
                    <Container>
                        {developerState ? text.dev : text.management}
                    </Container>
                    <p />
                    <Button
                        color="orange"
                        onClick={this.handleClick}
                        name={developerState ? "create_prototype" : "quality_dashboard"}
                    >
                        {developerState ? button.dev : button.management}
                    </Button>
                </Segment>
            </Grid.Column>
        );
    }

    renderLinkContent(content) {
        const { header, links } = content;
        return (
            <Grid.Column>
                {this.renderContentHeader(header)}
                <Segment attached>
                    <List>
                        {links.map((value, index) => {
                            const { text, href } = value;
                            return <List.Item as='a' key={index} onClick={this.handleClick} link={href}>{text}</List.Item>;
                        })}
                    </List>
                </Segment>
            </Grid.Column>
        );
    }

    renderLinkText(link, text) {
        return (
            <a target="_blank" href={link} rel="noopener noreferrer">
                {text}
            </a>
        );
    }

    renderAnnouncementContent(content) {
        return (
            <Container>
                {this.renderContentHeader("Announcements")}
                <Segment attached>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={2}>
                                <Image src={Announcement} />
                            </Grid.Column>
                            <Grid.Column width={14}>
                                <List>
                                    <List.Item>
                                        <b>Version 2.0 of QAP is officially released! (July 2023)</b>
                                        <List ordered>
                                            <List.Item>
                                                QAP now uses <b>Generative AI ({this.renderLinkText("https://aws.amazon.com/bedrock/", "Amazon Bedrock")})</b> to assist developers in fixing reported code analysis findings
                                            </List.Item>
                                            <List.Item>
                                                Developers can now provide feedback on code analysis findings (e.g., to suggest suppression or that severity is lowered)
                                            </List.Item>
                                            <List.Item>
                                                SMEs can review developer's feedback on findings and have QAP enforce suppression or severity change for a repo or an entire organization
                                            </List.Item>
                                            <List.Item>
                                                {this.renderLinkText("/quality_dashboard", "QAP's Dashboard")} highlights repositories that use "foundations"
                                            </List.Item>
                                            <List.Item>
                                                Full quality reports can now be generated from {this.renderLinkText("/quality_dashboard", "QAP's Dashboard")} to speed up ProtoSec review sessions
                                            </List.Item>
                                            <List.Item>
                                                Weekly email reports are now automatically sent to management highlighting analysis findings, statistics and trends
                                            </List.Item>
                                            <List.Item>
                                                Faster loading time for online reports and the main dashboard
                                            </List.Item>
                                        </List>
                                    </List.Item>
                                    <List.Item>
                                        Contact the QAP team {this.renderLinkText("https://amzn-aws.slack.com/archives/C02UY1UUMGC", "on Slack")} for details.
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
        )
    }

    render() {
        return (
            <Container>
                <Container>
                    <Image src={QualityHeader} />
                    <Header as='h1'>
                        <Header.Subheader>
                            {this.content.subtitle}
                        </Header.Subheader>
                    </Header>
                </Container>
                <Divider />
                {this.renderAnnouncementContent()}
                <Divider />
                <Header as='h3'>
                    Highlight: Leverage Amazon Bedrock and GenAI to improve code quality
                </Header>
                <Embed
                    id='qap-genai-teaser-video' // Provide a unique ID for the video
                    placeholder={GenAITeaserThumbnail} // Optional thumbnail image for the video
                    source='video' // Specify the video source as 'video'
                    url={GenAITeaserVideo} // Pass the imported video file to the `url` prop
                />
                <Divider />
                <Grid columns={2} divided textAlign="left">
                    <Grid.Row>
                        <Grid.Column width={10}>
                            {this.renderDashboardContainer(this.renderListContent(this.content.directions))}
                            {this.renderDashboardContainer(this.renderListContent(this.content.benefits_features))}
                            <br /><br /><br />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            {this.renderDashboardContainer(this.renderActionContent(this.content.action))}
                            {this.renderDashboardContainer(this.renderLinkContent(this.content.links[0]))}
                            {this.renderDashboardContainer(this.renderLinkContent(this.content.links[1]))}
                        </Grid.Column>
                        <br /><br /><br />
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}